
import { useState, useEffect } from 'react';
import {Modal, Button} from 'react-bootstrap';

export const PlayerModal = ({playerData, plStats}) => {
    const [show, setShow] = useState(true);

    // useEffect(() => {
    //     setShow(true);
    // }, [showModal])




    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Профил на играча</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-container'>
                    <div className='modal-photo'>
                        <img className='modal-profile-photo' src={playerData.picture} alt="" />
                    </div>
                    <div className='modal-data'>
                        <div className="modal-data-name">
                            <div className="name">{playerData.fname}</div>
                            <div className="name">{playerData.mname}</div>
                            <div className="name">{playerData.lname}</div>
                        </div>
                        <div></div>
                            <div>
                                <div className="data-label">Дата на раждане:</div>
                                <div className="data-value">{playerData.dob}</div>
                                <div className='clearfix'></div>
                            </div>
                            <div>
                                <div className="data-label">Брой срещи:</div>
                                <div className="data-value">{plStats.matches}</div>
                                <div className='clearfix'></div>
                            </div>
                            <div>
                                <div className="data-label">Сменен:</div>
                                <div className="data-value">{plStats.playerOut}</div>
                                <div className='clearfix'></div>
                            </div>
                            <div>
                                <div className="data-label">Смяна:</div>
                                <div className="data-value">{plStats.playerIn}</div>
                                <div className='clearfix'></div>
                            </div>
                            <div>
                                <div className="data-label">Игрално време:</div>
                                <div className="data-value">{plStats.playTime}</div>
                                <div className='clearfix'></div>
                            </div>                            <div>
                                <div className="data-label">Голове:</div>
                                <div className="data-value">{plStats.scored}</div>
                                <div className='clearfix'></div>
                            </div>                            <div>
                                <div className="data-label">Жълти картони:</div>
                                <div className="data-value">{plStats.yCards}</div>
                                <div className='clearfix'></div>
                            </div>                            <div>
                                <div className="data-label">Червени картони:</div>
                                <div className="data-value">{plStats.rCards}</div>
                                <div className='clearfix'></div>
                            </div>
                            
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button> */}
                {/* <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
            </Modal.Footer>
        </Modal>
        </>
    )
}