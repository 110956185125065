import { useState, useEffect } from "react";
import './matches.css';

import { lastRound } from "../../services/matchServise";
import { Round } from "../round/Round";

export const Matches = () => {
    const [roundArr, setRoundArr] = useState([]);
    // const [lastRnd, setLastRnd] = useState(40)

    useEffect(() => {
        const getData = async () => {
            const last = await lastRound();

            let arr = [];
            for (let x = 1; x <= last; x++) {
                arr.push(x);
            }

            setRoundArr(arr);
        }
        getData();

    }, [])


        return (
            <div id="full-season">
                <h1>Мачовете през сезона</h1>
                <div className="matches-container">
                    {roundArr.map((el) => 
                        <div className="round-container">
                            <h4>{el} кръг</h4>
                            <Round round={el} />
                        </div>  
                    )}
                </div>
            </div>
        )
}