import { useState, useEffect, useContext, createContext } from "react";
import { useParams } from "react-router-dom";
import { ArticlesContext } from "../../App";

import Modal from 'react-bootstrap/Modal';

import { getPlayersByTeam } from "../../services/matchServise";
import { PlayerRow } from "./PlayerRow";
import { PlayerModal } from "./PlayerModal";
import { center } from "@cloudinary/url-gen/qualifiers/textAlignment";

export const PlayerContext = createContext();

export const TeamsPage = () => {
    const {id} = useParams();
    const { teams } = useContext(ArticlesContext);

    const [currentTeam, setCurrentTeam] = useState();
    const [teamList, setTeamList] = useState([]);
    const [selPlayerData, setSelPlayerData] = useState({});
    const [showModal, setShowModal] = useState(false);



    useEffect(() => {
        const c = teams.find(el => el.id === id);
        setCurrentTeam(c);
        
    }, [teams, id])

    useEffect(() => {
        const getPlayers = async () => {
            const pList = await getPlayersByTeam(currentTeam.id);
            setTeamList(pList);
        }

        if(currentTeam) {
            getPlayers();
        }
    }, [currentTeam])

    const getSelectedPlayer = (pl) => {
        setSelPlayerData(pl);
        setShowModal(true);
    }



    return (
        <div id="teams">
              {currentTeam && 
            <div className="team-data">
                <div className="team-logo">
                    <img src={currentTeam.emblem} alt="" />
                </div>
                <div className="team-name">
                <h1>{currentTeam.customName}</h1>
                <h3>{currentTeam.city}</h3>

                </div>
            </div>          
            }

            <div id="team-list">
                <table id="tlist-table" className="table table-striped table-sm">
                <thead>
                        <tr>
                            <th>Име</th>
                            <th>Презиме</th>
                            <th>Фамилия</th>
                            <th style={{textAlign: 'center'}}>Възраст</th>
                            <th style={{textAlign: 'center'}}>Дата на раждане</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                <PlayerContext.Provider value={{selPlayerData, getSelectedPlayer}}>
                    {teamList.map((el) => 
                        <PlayerRow key={el.id} playerData={el} />
                        )}          
                {/* <PlayerModal show={showModal} playerData={selPlayerData} /> */}
                </PlayerContext.Provider>
                    </tbody>
        </table>
            </div>

        </div>
    )
}