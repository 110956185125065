import { Parse } from 'parse';

Parse.initialize(process.env.REACT_APP_B4A_APPID, process.env.REACT_APP_B4A_JSID); 
const serverUrl = 'https://parseapi.back4app.com/';
Parse.serverURL = serverUrl;


export async function uploadImage (imageData) {

    const formData = new FormData();
    formData.append("file", imageData);
    formData.append("upload_preset", "jutaahye");
    formData.append("folder", "football-samples"); 

    console.log('formdata: ', formData);

    try {
        let res = await fetch(`${process.env.REACT_APP_CLOUDINARY_URL}/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload`, {
                method: 'POST',
                body: formData
            })
        let resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error)
    }
}

export const delImage = async (id) => {
}

export const addArticle = async (data) => {
    Parse.serverURL = serverUrl;
    try {
        const Article = new Parse.Object('Article');
        Article.set('title', data.title);
        Article.set('imageID', data.imageID);
        Article.set('articleText', data.articleText);
        Article.set('userID', data.author);
        
        await Article.save();
    
    } catch(error) {
        throw new Error('Грешка при запис на публикацията!')
    }
}

export const countArticles = async () => {
    const query = new Parse.Query('Article');

    try {
        const count = await query.count();
        return count;
    } catch(error) {
        console.log(error);
    }
}

export const getArticles = async () => {
    let arr = [];
    const Article = Parse.Object.extend('Article');
    const query = new Parse.Query(Article);
    // You can also query by using a parameter of an object
    // query.equalTo('objectId', 'xKue915KBG');
    query.limit(50);
    query.descending('createdAt')
    try {
        const results = await query.find();
        for (const object of results) {
            let obj = {};
            // Access the Parse Object attributes using the .GET method
            obj.title = object.get('title');
            obj.imageID = object.get('imageID');
            obj.articleText = object.get('articleText');
            obj.userID = object.get('userID');
            obj.id = object.id;
            obj.updatedAt = object.updatedAt;

            arr.push(obj);
        }
        return arr;
    } catch (error) {
        console.error('Error while fetching Article', error);
    }
}

export const getArticleById = async (id) => {
    const Article = Parse.Object.extend('Article');
    const query = new Parse.Query(Article);
    query.equalTo('objectId', id);
    try {
        const obj = await query.find();
        let article = {};

        article.title = obj[0].get('title');
        article.imageID = obj[0].get('imageID');
        article.articleText = obj[0].get('articleText');
        article.userID = obj[0].get('userID');
        article.id = obj[0].id;
        article.updatedAt = obj[0].updatedAt;
        return article;
    } catch(error) {
        console.log(error)
    }
}

export const updateArticle = async (data) => {
    const Article = Parse.Object.extend('Article');
    const query = new Parse.Query(Article);
    try {
      // here you put the objectId that you want to update
      const object = await query.get(data.id);
      object.set('title', data.title);
      object.set('imageID', data.imageID);
      object.set('articleText', data.articleText);
      object.set('userID', data.author);
      try {
        const response = await object.save();
        // You can use the "get" method to get the value of an attribute
        // Ex: response.get("<ATTRIBUTE_NAME>")
        // // Access the Parse Object attributes using the .GET method
        // console.log(response.get('title'));
        // console.log(response.get('imageID'));
        // console.log(response.get('articleText'));
        // console.log(response.get('userID'));
        console.log('Article updated', response);
      } catch (error) {
        console.error('Error while updating Article', error);
        }
      } catch (error) {
        console.error('Error while retrieving object Article', error);
      }
}

export const deleteArticle = async (id) => {
    const Article = Parse.Object.extend('Article');
    const query = new Parse.Query('Article');
    try {
      // here you put the objectId that you want to delete
      const object = await query.get(id);
      try {
        const response = await object.destroy();
        console.log('Deleted ParseObject', response);
      } catch (error) {
        console.error('Error while deleting ParseObject', error);
      }
    } catch (error) {
      console.error('Error while retrieving ParseObject', error);
    }
}


