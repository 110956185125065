

export const roundMatches = async (rnd) => {

    try {
        let res = await fetch(`https://reactserver.comorex.net/round-matches?rnd=${rnd}`);
        const resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error);
    }
}

export const standingsData = async () => {
    try {
        let res = await fetch('https://reactserver.comorex.net/standings');
        const resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error);
    }

}

export const lastRound = async () => {
    try {
        let res = await fetch(`https://reactserver.comorex.net/lastround`);
        const resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error);
    }

}

export const getTeams = async () => {
    try {
        let res = await fetch(`https://reactserver.comorex.net/getTeams`);
        const resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error);
    }
}

export const getPlayersByTeam = async (tID) => {
    try {
        let res = await fetch(`https://reactserver.comorex.net/getPlayersByTeam?tid=${tID}`);
        const resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error);
    }
}

export const getPlayerProfile = async (pID) => {
    try {
        let res = await fetch(`https://reactserver.comorex.net/getPlayerStats?pid=${pID}`);
        const resData = await res.json();
        return resData;
    } catch(error) {
        console.log(error);
    }
}