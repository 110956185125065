import { useState, useEffect } from 'react';
import { Match } from './Match';
import { roundMatches } from '../../services/matchServise';
// import { async } from 'q';


export const Round = ({round}) => {
    const [roundData, setRoundData] = useState([]);


    useEffect(() => {
        const getRoundMatches = async () => {
            const data = await roundMatches(round);
            setRoundData(data);
        }

        getRoundMatches();

    }, [round])


    return (
        <div id="round">
            {roundData.map((el) => 
                <Match key={el.matchID} matchData={el} />
            )}
        </div>

    )
}