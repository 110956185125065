import { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";

import './singleArticle.css';
import { getArticleById } from "../../services/newsService";
import { convertDate } from "../../services/generalServices";

export const SingleArticle = () => {
    const { articleId } = useParams();
    const [art, setArt] = useState({
        title: '',
        image: '',
        text: '',
        updatedAt: ''
     });
     const [image, setImage] = useState('');


    useEffect(() => {
        async function getArticle () {
            const a = await getArticleById(articleId);
            const dt = convertDate(a.updatedAt);
            setArt({
                title: a.title,
                image: a.imageID,
                text: a.articleText,
                updatedAt: dt
             })

            const img = new CloudinaryImage(a.imageID, {cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME}).resize(fill().width(500));
            setImage(img);
            
        }
        getArticle();
    }, []);


    return (
        <div id="single-article">
            <h1>{art.title}</h1>
            <div className="article">
                <div className="image">
                    <AdvancedImage cldImg={image}/>
                </div>
                <div className="text">
                    <div className="date">{art.updatedAt}</div>
                    
                    {art.text}
                </div>
            </div>
        </div>

    )
}