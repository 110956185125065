import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './login.css';
import { loginAction } from '../../actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../services/authSevice';
// import { useSelector } from 'react-redux';

// const userStatus = state => state.userState;

export const Login = () => {
    // const userState = useSelector(userStatus);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [user, setUser] = useState({
        email: '',
        password: '',
    });


    const onChangeHandler = (e) => {
        setUser(state => ({...state, [e.target.name]: e.target.value}))
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (!user.email || !user.password) {
            alert("Попълнете празните полета")
        }

        const resp = await login(user);

        
        if (resp !== Error) {
            const newUserState = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_B4A_APPID}/currentUser`));
            dispatch(loginAction(newUserState));
            navigate('/');
        }


        
    }

    // if (userState.isLogged) {
    //     navigate('/')
    // } else {
    return (
        <div id="login" className='login-box'>
            <h1>Вход:</h1>
            <div className='login-form'>
                <form onSubmit={onSubmitHandler}>
                   {/* <!-- Email input --> */}
                   <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="email">Email адрес</label>
                        <input type="email" id="email" className="form-control" name="email" value={user.email} onChange={onChangeHandler} />
                    </div>

                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="password">Парола</label>
                        <input type="password" id="password" className="form-control" name="password"  value={user.password} onChange={onChangeHandler}/>
                    </div>


                    {/* <!-- Submit button --> */}
                    <button type="submit" className="btn btn-primary btn-block mb-4">Влезте</button>

                    {/* <!-- Register buttons --> */}
                    <div className="text-center">
                        <p>Нямате регистрация? <Link to={'/register'}>Регистрирайте се!</Link></p>
                    </div>
                </form>
            </div>

        </div>
    )
    // }
}