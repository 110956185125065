import { Routes, Route, BrowserRouter } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';

import { Header } from './components/header/Header';
import { Home } from './components/home/Home';
import { Login } from './components/login/Login';
import { Register } from './components/register/Register';
import { Logout } from './components/logout/Logout';
import { AddStory } from './components/add-story/AddStory';
import { EditStory } from './components/edit-story/EditStory';
import { Matches } from './components/matches/Matches';
import { TeamsPage } from './components/teams/TeamsPage';
import { SingleArticle } from './components/singleArticle/SingleArticle';

import { getArticles } from './services/newsService';
import { getTeams } from './services/matchServise';
import { standingsData } from './services/matchServise';

import { createContext, useEffect, useState } from 'react';

export const ArticlesContext = createContext();

function App() {
 const [articles, setArticles] = useState([]);
 const [teams, setTeams] = useState([]);
 const [stand, setStand] = useState([]);

useEffect(() => {
        async function getAllArticles () {
            const art = await getArticles();
            setArticles(art);

            const t = await getTeams();
            setTeams(t);
        }

        getAllArticles();
}, []);


useEffect(() => {
    const getStandings = async () => {
        const s = await standingsData();
        setStand(s);
    }

    getStandings();

}, [])



  return (
    <div>
        <ArticlesContext.Provider value={{articles, setArticles, teams, stand}}>
        <BrowserRouter>
        <Header />
        <Container>
        <main>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path="/add-story" element={<AddStory />} />
                    <Route path="/articles/:articleId/edit" element={<EditStory />} />
                    <Route path="/matches" element={<Matches />} />
                    <Route path="/teams/:id" element={<TeamsPage />} />
                    <Route path="/articles/:articleId" element={<SingleArticle />} />
                </Routes>
        </main>
        </Container>  

        </BrowserRouter>
        </ArticlesContext.Provider>    
    </div>
  );
}

export default App;
