import 'bootstrap/dist/css/bootstrap.min.css';
import './home.css';

import { useState, useEffect,  useContext } from "react";
import { Teaser } from "./Teaser";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { getArticles } from '../../services/newsService';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ArticlesContext } from '../../App';


const articlesPerPage = 10;
const userStatus = state => state.userState;


export const Articles = () => {
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [pageArticles, setPageArticles] = useState([]);
    const [isEditor, setIsEditor] = useState(false);

    const userState = useSelector(userStatus);
    // const location = useLocation();

    const {articles, setArticles } = useContext(ArticlesContext);

    useEffect(() => {
        setPage(1)
    }, [])
    
    useEffect(() => {
        setTotal(articles.length);
    }, [articles]);

    
    useEffect(() => {
        let start = (page - 1) * articlesPerPage;
        let end = 0;
        if ((start + articlesPerPage) >= articles.length) {
            end = articles.length - 1;
        } else {
            end = start + articlesPerPage;
        }
        let arr = articles.slice(start, end);
        setPageArticles(arr);
    }, [page, total]);

    useEffect(() => {
        if (userState.user != null) {
            if (userState.user.role === 'editor') {
                setIsEditor(true)
            }
        }

    }, [userState.user])


     return(
        <>
            {isEditor && 
                    <div className='add-article'>
                        <Link to="/add-story">
                            <button className={'btn btn-primary'}>
                                Добави публикация
                            </button>
                        </Link>
                </div>
            }

            {pageArticles.map((art) => 
                <Teaser key={art.id} art={art} /> 
            )}
            <PaginationControl
                page={page}
                between={3}
                total={total}
                limit={articlesPerPage}
                changePage={(page) => {
                setPage(page); 
                }}
                ellipsis={1}
            />
        </>


    )
}