import { useEffect, useState, useContext } from "react";
import { ImageContext} from './AddStory';


export const AddImage = () => {
    const [file, setFile] = useState({});
    const [imgData, setImgData] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const { selectImage, imageSetter } = useContext(ImageContext);

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        let reader, isCancel = false;
        if (file.name) {
                reader = new FileReader();
                reader.onload = (e) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setImgData(result);
                    }
                }
                reader.readAsDataURL(file);
                
          
                setIsUploaded(true);
                imageSetter(file);
        }
        return () => {
            isCancel = true;
            if (reader && reader.readyState === 1) {
                reader.abort();
            }
        }
    }, [file])

   


    const deleteImage = (e) => {
        e.preventDefault();
        setFile({});
        setIsUploaded(false);
        imageSetter({})
    }

    return(
        <div className="image-upload">
            
                <div className="image-form">
                    <label className="form-label" htmlFor='image'>Изображение</label>
                    {!isUploaded &&
                        <input className="form-control" type='file' name="image" onChange={onFileChange}/>
                    }
                    {isUploaded &&
                        <div className="thumb-wrapper">
                            <img className="image-thumb" src={imgData} alt="" />
                            {/* <AdvancedImage cldImg={thumb}/> */}
                            <button onClick={deleteImage}>Изтрий</button>
                            <p>Избраният файл е: {file.name}</p>

                        </div>
                    }
                </div>


        </div>


    )
}