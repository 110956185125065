import { Types } from "../constants/actionTypes";


export const registerAction = (userData) => {
    return {
        type: Types.REGISTER,
        payload: userData

    }
}

export const loginAction = (userData) => {
    return {
        type: Types.LOGIN,
        payload: userData
    }

}

export const logOutAction = (userData) => {
    return {
        type: Types.LOGOUT,
    }

}