import { useState, useEffect, useContext } from "react";
// import { standingsData } from "../../services/matchServise";
import { ArticlesContext } from "../../App";
import './standings.css';

export const Standings = () => {
    const {stand} = useContext(ArticlesContext);

    return (
    <>
        <div id="standings">
        <div className="stats-header">Класиране</div>
             <table id="standings-table" className="table table-striped table-sm">
                <tbody>
                {stand.map((el) => 
                    <tr key={el.place}>
                        <td>{el.place}</td>
                        <td>{el.name}</td>
                        <td>{el.values.matches}</td>
                        <td>{el.values.wins}</td>
                        <td>{el.values.draws}</td>
                        <td>{el.values.lost}</td>
                        <td>{el.values.goalsScored}:{el.values.goalsConceded}</td>
                        <td>{el.values.points}</td>
                    </tr>
                    )}
                    
                </tbody>
            </table> 
        </div>
    </>
    )
}