import { useState, useEffect } from 'react';
import { register } from '../../services/authSevice';
import { useSelector, useDispatch } from 'react-redux';
import { registerAction } from '../../actions/authActions';
import { Link, useNavigate } from 'react-router-dom';

import './register.css';


const userStatus = state => state.userState;

export const Register = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState({
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        role: 'user'
    });

    const userState = useSelector(userStatus);


    const navigate = useNavigate();


    const onChangeHandler = (e) => {
        setUser(state => ({...state, [e.target.name]: e.target.value}))
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if (user.password !== user.repeatPassword) {
            alert("Паролите не съвпадат")
        }

        if (!user.username) {
            alert("Попълнете празните полета")
        }

        if (userState.user !== null && userState.user.role === 'admin') {
            setUser(...user, {role: 'editor'})
        }

        
        const resp = await register(user);

        
        if (resp !== Error) {
            const newUserState = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_B4A_APPID}/currentUser`));
            dispatch(registerAction(newUserState));
            navigate('/');
        }


        // dispatch(registerAction(user));
    }



    return (
        <div id="register" className='register-box'>
            {userState.userRole === 'admin' && 
                <h1>Регистрация на редактор:</h1>
            }
            {!userState.userRole === 'admin' && 
                <h1>Регистрация:</h1>
            }
            <div className='register-form'>
            <form onSubmit={onSubmitHandler}>
                    {/* <!-- Name input --> */}
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="username">Име</label>
                        <input type="text" id="username" className="form-control" name="username" value={user.username} onChange={onChangeHandler} />
                    </div>

                    {/* <!-- Email input --> */}
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="email">Email адрес</label>
                        <input type="email" id="email" className="form-control" name="email"  value={user.email} onChange={onChangeHandler} />
                    </div>

                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="password">Парола</label>
                        <input type="password" id="password" className="form-control" name="password"  value={user.password} onChange={onChangeHandler} />
                    </div>

                    {/* <!-- Repeat Password input --> */}
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="repeatPassword">Потвърдете паролата</label>
                        <input type="password" id="repeatPassword" className="form-control" name="repeatPassword"  value={user.repeatPassword} onChange={onChangeHandler} />
                    </div>

                    {/* <!-- Submit button --> */}
                    <button type="submit" className="btn btn-primary btn-block mb-4">Влезте</button>

                    {/* <!-- Register buttons --> */}
                    <div className="text-center">
                        <p>Имате регистрация? <Link to={'/login'}>Влезте!</Link></p>
                    </div>
                </form>
            </div>

        </div>
    )
}