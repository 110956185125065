import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../actions/authActions";
import { logOut } from "../../services/authSevice";

export const Logout = () => {
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const lOut = async () => {
            await logOut();
        }
    
        const resp = lOut();
    
        if (resp !== Error) {
            dispatch(logOutAction());
            navigate('/');
        }

    }, []);

}