import { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ArticlesContext } from '../../App';


const userStatus = state => state.userState;

export const Header = () => {
  const userState = useSelector(userStatus);
  const { teams } = useContext(ArticlesContext);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
            <img src="logo.png" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to='/'>Новини</Nav.Link>
            <Nav.Link as={Link} to="/matches">Мачове</Nav.Link>
            <NavDropdown title="Отбори" id="collasible-nav-dropdown">
              {teams.map((el) => 
                <NavDropdown.Item key={el.id} as={Link} to={`/teams/${el.id}`}>{el.customName}</NavDropdown.Item>
              )}
              {/* <NavDropdown.Item as={Link} to="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
          <Nav>
            {!userState.isLogged && 
                <>
                <Nav.Link as={Link} to="/login">Вход</Nav.Link>
                <Nav.Link eventKey={2} as={Link} to="/register">Регистрация</Nav.Link>
                </>

            }
            {userState.isLogged && 
                <>
                <Nav.Link as={Link} to="/">Потребител: {userState.user.username}</Nav.Link>
                {userState.user.role === 'admin' &&
                    <Nav.Link as={Link} to="/register">Регистрация на редактор</Nav.Link>
                }
                <Nav.Link as={Link} to="/logout">Изход</Nav.Link>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
