import { useState, useEffect } from 'react';
import { Articles } from './Articles';
import { Round } from '../round/Round';
import { Standings } from '../standings/Standings';

import { lastRound } from "../../services/matchServise";


export const Home = () => {
    const [round, setRound] = useState(0);

    useEffect(() => {
        const getData = async () => {
            const lastRnd = await lastRound();

            setRound(lastRnd);
        }
        getData();

    }, [])

    return (
        <div className='content'>
            <div className='left-pane'>
                <Articles />
            </div>
            <div className='right-pane'>
                <div className='stats-header'>Последни резултати</div>
                <Round round={round}/>
                <Standings />
            </div>
            

        </div>
    )
}
