import './round.css'

export const Match = ({matchData}) => {
    return (
        <div className="match">
            <div className="match-date">{matchData.date}</div>
            {/* <div className="team-name">{matchData.homeName}</div> - <div className="team-name">{matchData.awayName}</div> */}
            <div className="team-name">{matchData.homeName}</div> – <div className="team-name">{matchData.awayName}</div>
            <div className='result'>{matchData.scoreHost}:{matchData.scoreAway}</div>
        </div>
    )
}