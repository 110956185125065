import { Parse } from 'parse'

Parse.initialize(process.env.REACT_APP_B4A_APPID, process.env.REACT_APP_B4A_JSID); 
const serverUrl = 'https://parseapi.back4app.com/';

export const register = async (data) => {

    Parse.serverURL = serverUrl;
    
    const {username, email, password, role} = data;

    let user = new Parse.User();
    user.set('username', username);
    user.set('email', email);
    user.set('password', password);
    user.set('role', role);

    try {
        const createdUser = await user.signUp();
        alert(`Потребителят ${username} е регистриран успешно!`)
        return createdUser;
        
    } catch(error) {
        if (error.code === 202) {
            alert("Такъв акаунт вече съществува!")
        }
        throw new Error(error);
    }
}

export const login = async (data) => {
    const {email, password} = data;
    // const [currentUser, setCurrentUser] = useState(null);

    Parse.serverURL = serverUrl;

    try {
        let resp = await Parse.User.logIn(email, password);
        // alert(`Добре дошъл, ${resp.get('username')}`);

        const currentUser = await Parse.User.current();
    
        return resp;
    } catch (error) {
        return error;
    }
}

export const logOut = async function () {
    Parse.serverURL = serverUrl;
    try {
      let res = await Parse.User.logOut();
      const currentUser = await Parse.User.current();

      return true;
    } catch (error) {
      alert(`Error! ${error.message}`);
      return false;
    }
  };

  export const getCurrentUser = async () => {
    const currentUser = await Parse.User.current();
    return currentUser;
  }
  

