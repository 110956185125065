import { register, login, logOut } from '../services/authSevice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser } from '../services/authSevice';


const user = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_B4A_APPID}/currentUser`));
// const user = getCurrentUser();

let isLogged = false;
if (user) {
    isLogged = true;
} 


const initialState = {
    user: user,
    isLogged: isLogged,
}


export const userState = (state = initialState, action) => {
    let newUserState = {};
    
    switch (action.type) {
        case 'REGISTER':
            // register(action.payload);
            // newUserState = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_B4A_APPID}/currentUser`));
            return {...state, user: action.payload, isLogged: true};
        case 'LOGIN':
            return {...state, user: action.payload, isLogged: true};
        case 'LOGOUT':
            // logOut();
            return {...state, user: null, isLogged: false};
        default:
            return state;
    }
}



// export const isLogged = (state = false action) {
     
// }