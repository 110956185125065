
export const convertDate = (dt) => {

    const d = dt.getDate();
    const m = dt.getMonth();
    const y = dt.getFullYear();
    const h = dt.getHours();
    const min = dt.getMinutes();


    let month = '';

    switch (m) {
        case 0:
            month = 'януари';
            break;
        case 1:
            month = 'февруари';
            break;
        case 2:
            month = 'март';
            break;
        case 3:
            month = 'април';
            break;
        case 4:
            month = 'май';
            break;
        case 5:
            month = 'юни';
            break;
        case 6:
            month = 'юли';
            break;
        case 7:
            month = 'август';
            break;
        case 8:
            month = 'септември';
            break;
        case 9:
            month = 'октомври';
            break;
        case 10:
            month = 'ноември';
            break;
        case 11:
            month = 'декември';
            break;
        default:
            month = "";
    }

    let data = d + " " + month + " " + y + " г., " + h + ":" + min + " ч."

    return data;
}

