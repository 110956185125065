import { useState, useContext, createContext, useEffect } from 'react';
import { ArticlesContext } from '../../App';
import { useNavigate, useParams } from 'react-router-dom';
import { EditImage } from './EditImage';
import './editStory.css';
import { getCurrentUser } from '../../services/authSevice';
import { updateArticle, getArticleById, uploadImage } from '../../services/newsService';


export const ImageContext = createContext();

export const EditStory = () => {
    const { articleId } = useParams();

    const [selectImage, setSelectImage] = useState('');
    const [loadedImage, setLoadedImage] = useState('');
    const [title, setTitle] = useState('');
    const [newArticle, setNewArticle] = useState('');

    const { articles, setArticles } = useContext(ArticlesContext);
    const navigate = useNavigate();

    useEffect(() => {
        async function getArticle () {
            const art = await getArticleById(articleId);
            setTitle(art.title);
            setNewArticle(art.articleText);
            setLoadedImage(art.imageID);
            return art;
        }
        getArticle();
    }, []);

    const imageSetter = (img) => {
        setSelectImage(img);
    }

    const onTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const onArticleChange = (e) => {
        setNewArticle(e.target.value)
    }

    const onCancel = () => {
        navigate('/')
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const currentUser = await getCurrentUser();
        let iID = '';
        if (selectImage !== '') {
            const uploadImageData = await uploadImage(selectImage);
            iID = uploadImageData.public_id;
        } else {
            iID = loadedImage;
        }
        const data = {
            'title': title,
            'imageID': iID,
            'articleText': newArticle,
            'author': currentUser.id,
            'id': articleId
        }
        updateArticle(data);
        updateArticlesArr(data);

        navigate('/', {state:{refresh:true}});
    }

    const updateArticlesArr = (data) => {
        const newArr = articles.map(obj => {
            if (obj.id === articleId) {
                return {...obj, ...data};
            }
            return obj;
        });
        setArticles(newArr);
    }

  
    
    return (
        <div id="edit-story">
            <h1>Редактиране на новина</h1>

            <form onSubmit={onSubmit}>
                <label className='form-label' htmlFor='title'>Заглавие</label>
                <input className='heading form-control' type='text' name="title" value={title} onChange={onTitleChange} required />
                <ImageContext.Provider value={{ selectImage, loadedImage, setLoadedImage, imageSetter}} >
                    <EditImage />
                </ImageContext.Provider>
                <label className='form-label' htmlFor='article-text'>Текст</label>
                <textarea className='form-control article-text' type='text' name="article-text" rows={12} value={newArticle} onChange={onArticleChange} required />
                {/* <button type='submit' onSubmit={onSubmit}>Запиши</button> */}
                <input className='btn btn-primary' type='submit' value='Изпрати' />
                <button className='btn btn-primary' type='button' onClick={onCancel}>Отказ</button>   
            </form>

            

        </div>
    )
}