import { useState, useEffect, useContext } from "react"
import { AdvancedImage } from "@cloudinary/react";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { Link } from "react-router-dom";
import './home.css';
import { ArticlesContext } from '../../App';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";


import { deleteArticle } from "../../services/newsService";

const userStatus = state => state.userState;

export const Teaser = (a) => {
    // const [data, setData] = useState({title: '', imageID: ''});
    const [img, setImg] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [teaserStyle, setTeaserStyle] = useState("teaser-link");
    const [editStyle, setEditStyle] = useState({color: '#174879',})
    const [delStyle, setDelStyle] = useState({color: '#174879',})
    const {art} = a;
    
    const { articles, setArticles } = useContext(ArticlesContext);
    const userState = useSelector(userStatus);

    useEffect(() => {
        if (art.imageID !== '') {
            const t = new CloudinaryImage(art.imageID, {cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME}).resize(fill().width(150));
            setImg(t);
         }
    }, [art]);

    useEffect(() => {
        if(userState.user && (art.userID === userState.user.objectId || userState.user.role === 'admin')) {
            setIsOwner(true);
        }
    }, [art.userID, userState]);

    const onClickDeleteHandler = (e) => {
        // e.prevent.default();
        deleteArticle(art.id);
        let newArticleArr = [];
        articles.map(el => {
            if (el.id !== art.id) {
                newArticleArr.push(el);
            }
        })
        setArticles(newArticleArr);
    }

    const onMouseOver = () => {
        setTeaserStyle("teaser-link-hover");
    }

    const onMouseOut = () => {
        setTeaserStyle("teaser-link");
    }


    return(
        <div className="teaser">
            <div className={teaserStyle} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <Link to={`/articles/${art.id}`} style={{color: 'inherit', textDecoration: 'inherit', display: 'flex'}}>
                    <div className="teaser-image">
                        <AdvancedImage cldImg={img} />
                    </div>
                    <div className="teaser-heading">
                        <h4>{art.title}</h4>
                    </div>
                </Link>
            </div>

            {(isOwner) &&
                <div className="buttons">
                    <div className="but">
                    <Link to={`/articles/${art.id}/edit`}>
                        <FontAwesomeIcon 
                            icon={faPenToSquare} 
                            style={editStyle}
                            size="2x"
                            onMouseOver={() => {setEditStyle({color: 'red',})}}
                            onMouseOut={() => {setEditStyle({color: '#174879',})}}
                        />
                    </Link> 
                    </div>
                    <div className="but">
                            <FontAwesomeIcon 
                                icon={faTrash} 
                                size="2x"
                                style={delStyle}
                                onMouseOver={() => {setDelStyle({color: 'red',})}}
                                onMouseOut={() => {setDelStyle({color: '#174879',})}}
                                onClick={onClickDeleteHandler}
                            />
                    </div>



                    {/* <Link to={`/articles/${art.id}/edit`}><button className="btn btn-secondary btn-sm" style={{display: "block"}}>Редакция</button></Link>
                    <button className="btn btn-secondary btn-sm" style={{display: "block"}} onClick={onClickDeleteHandler}>Премахване</button> */}
                </div>
            }
        </div>
    )
}