import { useState, useContext, createContext } from 'react';
import { ArticlesContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { AddImage } from './AddImage';
import './addStory.css';
import { getCurrentUser } from '../../services/authSevice';
import { addArticle, uploadImage } from '../../services/newsService';


export const ImageContext = createContext();

export const AddStory = () => {
    const [selectImage, setSelectImage] = useState('');
    const [title, setTitle] = useState('');
    const [newArticle, setNewArticle] = useState('');

    const { articles, setArticles } = useContext(ArticlesContext);
    const navigate = useNavigate();

    const imageSetter = (img) => {
        setSelectImage(img);
    }

    const onTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const onArticleChange = (e) => {
        setNewArticle(e.target.value)
    }

    const onCancel = () => {
        navigate('/')
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const currentUser = await getCurrentUser();
        const uploadImageData = await uploadImage(selectImage);
        const iID = uploadImageData.public_id;
        const data = {
            'title': title,
            'imageID': iID,
            'articleText': newArticle,
            'author': currentUser.id
        }
        addArticle(data);
        let prevState = articles;
        prevState.unshift(data);
        setArticles(prevState);
        // navigate('/');
        navigate('/', {state:{refresh:true}});
    }
    
    return (
        <div id="add-story">
            <h1>Добавяне на новина</h1>

            <form onSubmit={onSubmit}>
                <label className='form-label' htmlFor='title'>Заглавие</label>
                <input className='form-control' type='text' name="title" value={title} onChange={onTitleChange} required />
                <ImageContext.Provider value={{ selectImage, imageSetter}} >
                    <AddImage />
                </ImageContext.Provider>
                <textarea className='form-control article-text' type='text' name="article-text" rows={12} value={newArticle} onChange={onArticleChange} required />
                {/* <button type='submit' onSubmit={onSubmit}>Запиши</button> */}
                <input type='submit' value='Изпрати' />
                <button type='button' onClick={onCancel}>Отказ</button>   
            </form>

            

        </div>
    )
}