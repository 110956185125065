import { useEffect, useState, useContext } from "react";
import { ImageContext} from './EditStory';
import { CloudinaryImage } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";


export const EditImage = () => {

    const [ file, setFile ] = useState({});
    const [ imgData, setImgData ] = useState(null);
    // const [ isUploaded, setIsUploaded ] = useState(false);
    const [ status, setStatus] = useState(0);
    const [ thumb, setThumb] = useState('');
    const { selectImage, loadedImage, setLoadedImage, imageSetter } = useContext(ImageContext);

    // status: 0 - default, 1 - selection, 2 - selected


    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        if (status === 0) {
            setStatus(0);
            const t = new CloudinaryImage(loadedImage, {cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME}).resize(fill().width(150));
            setThumb(t);
        }
    }, [loadedImage])

    useEffect(() => {
        let reader, isCancel = false;
        if (file.name) {
                reader = new FileReader();
                reader.onload = (e) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setImgData(result);
                    }
                }
                reader.readAsDataURL(file);
                
                setStatus(2);
                // setIsUploaded(true);
                imageSetter(file);
        }
        return () => {
            isCancel = true;
            if (reader && reader.readyState === 1) {
                reader.abort();
            }
        }
    }, [file])

    const deleteImage = (e) => {
        e.preventDefault();
        setFile({});
        setStatus(1);
        // setIsUploaded(false);
        setLoadedImage('');
        // setIsDefault(false);
        imageSetter({})

    }

    if (status === 1) {
        return (
            <div className="image-form">
                <label className="form-label" htmlFor='image'>Изображение</label>
                <input className="form-control" type='file' name="image" onChange={onFileChange}/>
            </div>
        )
    } else if (status === 0) {
       return (
            <div className="image-upload">
                <div className="image-form">
                    <label className="form-label" htmlFor='image'>Изображение</label>
                    <div className="thumb-wrapper">
                            <AdvancedImage cldImg={thumb}/>
                            <button className='btn btn-primary' onClick={deleteImage}>Изтрий</button>
                        </div>
                </div>
            </div>
        )
        } else {
            return (
            <>
                <label className="form-label" htmlFor='image'>Изображение</label>                   
                <div className="image-upload">
                        <div className="thumb-wrapper">
                            <img className="image-thumb" src={imgData} alt="" />
                            <button className="btn btn-primary" onClick={deleteImage}>Изтрий</button>
                        </div>
                </div>                
            </>
            )
        } 
}