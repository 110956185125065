import {useState, useEffect, useContext} from 'react';
// import {Modal, Button} from 'react-bootstrap';
import { getPlayerProfile } from '../../services/matchServise';
import './teams.css';
import { PlayerModal } from './PlayerModal';
// import { PlayerContext } from './TeamsPage';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';



const userStatus = state => state.userState;

export const PlayerRow = ({playerData}) => {
    // const { selPlayerData, getSelectedPlayer } = useContext(PlayerContext);
    const [show, setShow] = useState(false);
    const [stats, setStats] = useState({});
    const [iconStyle, setIconStyle] = useState({color: 'blue',});

    const userState = useSelector(userStatus);


    
    const onProfileClick = async () => {
        const plStats = await getPlayerProfile(playerData.id);
        setShow(true);
        setStats(plStats);

    }


    
    return (
        <>
        <tr>
            <td>{playerData.fname}</td>
            <td>{playerData.mname}</td>
            <td>{playerData.lname}</td>
            <td className="age">{playerData.years}</td>
            <td>{playerData.dobConverted}</td>
            {userState.isLogged &&
                // <td><button onClick={onProfileClick}>Профил</button></td>
                <td>
                    <FontAwesomeIcon 
                        icon={faAddressCard} 
                        onClick={onProfileClick}
                        onMouseOver={() => {setIconStyle({color: 'red',})}}
                        onMouseOut={() => {setIconStyle({color: '#0202d2',})}}
                        style={iconStyle}
                    />
                </td>
            }
        </tr>
        {show &&
            <PlayerModal playerData={playerData} plStats={stats} />
        }

       </>
    )
}